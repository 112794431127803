
.formio-component-select {
    .formio-choices {
        &[data-type='select-multiple'] {
            .form-control {
                min-height: 2.6rem;
                max-width: 18rem;
            }
        }
        .form-control {
            padding-bottom: 0;
        }

        .choices__item--selectable:not(.choices__item--choice) {
            max-width: 14rem;
        }

        .choices__list:not(.choices__list--multiple) {

            .formio-choices:not(.is-disabled):hover {
                .choices__item--selectable:not(.choices__item--choice) {
                    max-width: fit-content;
                    background: var(--background);
                }
            }

        }

        // &:hover {
        //     .choices__item--selectable:not(.choices__item--choice) {
        //         max-width: fit-content;
        //     }
        // }
    }
    .choices__list--multiple {
        .choices__item {
            height: 1.77rem;
            line-height: 1.5rem;
            border-radius: 0.35rem;
            background: #222325;
            border-color: #222325;
            padding-left: 0.9rem;

            .choices__button {
                border-left-color: white;
            }
        }

        .choices__item--selectable:has(button) {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 4px;
            width: fit-content;
            max-width: 100%;
            text-overflow: ellipsis;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }
    }
    .is-open {
        .choices__list--dropdown {
            border: 0.09rem solid #dadee2;
            box-shadow: 0 0.6rem 1.8rem rgba(27, 49, 82, 0.21);
        }
    }
    div.form-control[disabled="disabled"],
    .is-disabled {
        .choices__list--single {
            height: 100%;
            .choices__item--selectable {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: $main-font-size;
                line-height: 125%;;
                font-weight: 400;

                span {
                    font-size: $main-font-size;
                    white-space: normal;
                }
            }
        }
    }
    .choices__input {
        appearance: none;
    }
    .choices[data-type*=select-one]:after {
        border: none;
        background: url('../../icons/select-arrow.svg');
        background-repeat: no-repeat;
        width: 1.2rem;
        height: 1rem;
        margin-top: 0;
        top: 37%;
    }
    .choices[data-type*=select-one].is-open:after {
        margin-top: 0;
    }
    .dropdown {
        background: var(--input-bg);
    }
    .choices__list--dropdown {
        background: var(--input-bg);
    }
    .choices__input {
        background: var(--input-bg) !important;
        border-color: var(--input-border-color) !important;
        &:hover {
            border-color: var(--input-border-color-hover) !important;
        }
    }
    .choices__list--dropdown .choices__item--selectable.is-highlighted {
        background: var(--selected-select-item);
    }


    .choices__item.choices__item--selectable {
        white-space: nowrap;
    }


    .choices__item.choices__item--selectable[role="option"] {
        white-space: normal;

        span {
            white-space: normal;
            overflow-wrap: normal;
            word-break: normal;
        }
    }
}
